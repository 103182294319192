@charset "utf-8";

#header {
    position: relative;
    .navbar {
      background-color: #fff;
      padding: 0;

      @include tabletL {
          height: 45px;

          .navbar-collapse {
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
            height: 100vh;
        }
      }
    }
    .navbar-expand-lg .navbar-nav {
        margin-right: 120px;

        @include tabletL {
            background-color: #0071be;
            margin-right: 0;
            padding: 0 0 50px;
            margin-top: 0;

        }

        .current::after {
        position: absolute;
        bottom: -24px;
        left: 0;
        content: "";
        width: 100%;
        height: 4px;
        background: #0071be;
        }

    }
    .nav-item {
        margin: 0 30px;
        position: relative;

        @media screen and (max-width: 1680px) {
            margin: 0 20px;
        }

        @media screen and (max-width: 1440px) {
            margin: 0 15px;
        }

        @media screen and (max-width: 1280px) {
            margin: 0 5px;
        }

        &:nth-child(6),
        &:nth-child(7) {
            &::after {
                display: none;
            }
        }

        &::after {
            position: absolute;
            bottom: -27px;
            left: 0;
            content: "";
            width: 100%;
            height: 4px;
            background: #0071be;
            transform: scale(0, 1);
            transform-origin: center top;
            transition: transform .3s;
          }
          &:hover::after {
            transform: scale(1, 1);
          }

          @include tabletL {
            width: 100%;
            padding: 15px 20px;
            border-bottom: 1px #fff solid;

            &:nth-child(6),
            &:nth-child(7) {
                border-bottom: 0;
            }
            &::after {
                display: none;
            }
        }

        &.dropdown {
            &::after {
                bottom: 1px;
            }
        }

    }
    .navbar-brand {
        margin-left: 30px;
        width: 250px;
        max-width: 100%;
        text-align: center;

        @media screen and (max-width: 1280px) {
            width: 180px;
        }

        @media screen and (max-width: 1028px) {
            width: 160px;
        }

        @include tabletL {
            margin: 5px 15px;
        }

    }
    .nav-link {
      color: #00349d;
      font-size: calc(12px + 4 * (100vw - 375px) / 1545);
      text-align: center;
      line-height: normal;
      position: relative;

      @include tabletL {
          color: #fff;
          text-align: left;
          padding-left: 20px;
          position: relative;

          &::before {
            content: "";
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            display: inline-block;
            width: 8px;
            height: 8px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0,-50%) rotate(45deg);
          }
          &::after {
              display: none;
          }
      }

      &.tel-link {
        color: #00349d;

        &::after {
            display: none !important;
        }

        span {
            &:first-child {
                font-size: calc(18px + 14 * (100vw - 375px) / 1545);
                font-weight: 600;
            }

            &:last-child {
                font-size: calc(10px + 4 * (100vw - 375px) / 1545);
            }
        }
      }
    }

    .navbar-toggler {
        background-color: #0071be;
        border-radius: 0;
        border: none;
        padding: 5px;
        width: 45px;
        height: 45px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
    }

    .navbar-toggler-icon {
      background-image: none;
      background-color: #fff;
      width: 20px;
      height: 2px;
      display: block;
      position: absolute;
      transition: ease .5s;
      left: 50%;
      transform: translate(-50%, 0);

      &:nth-of-type(1) {
        top: 12px;
      }

      &:nth-of-type(2) {
        top: 19px;
      }

      &:nth-of-type(3) {
        top: 26px;
      }
    }

    .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
      &:nth-of-type(1) {
        top: 19px;
        transform: translateX(-50%) rotate(45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        top: 19px;
        transform: translateX(-50%) rotate(-45deg);
      }
    }

    .dropdown {
        .dropdown-link {
            padding: 2.8rem 1rem;
            font-size: calc(12px + 4 * (100vw - 375px) / 1545);

            @include tabletL {
                padding: .5rem 1rem;
                color: #fff;
            }
        }
        a {
            display: inline-block;
            text-decoration: none;
        }
        .dropdown-toggle-split {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 20px;
            transform: translate(0, 0);
            background-color: #fff;
            margin-top: 8px;
        }
        @include tabletL {
            .dropdown-toggle {
                &[data-toggle=dropdown] {
                    display: inline-block;
                    width: 45px;
                    height: 45px;
                }

                &[data-toggle="dropdown"] {
                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 16px;
                        height: 2px;
                        transform: translate(-50%, -45%) rotate(0deg);
                        background: #046fb9;
                        border-width: 2px;
                        border-color: #046fb9;
                        display: inline-block;
                        transition: all .3s ease-in-out;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 34%;
                        width: 16px;
                        height: 2px;
                        transform: translate(0, -45%) rotate(0deg);
                        background: #046fb9;
                        border-width: 2px;
                        border-color: #046fb9;
                        display: inline-block;
                        transition: all .3s ease-in-out;
                        -webkit-transition: 0.5s;
                        -o-transition: 0.5s;
                        transition: 0.5s;
                    }
                }

                &[aria-expanded="false"]::after {
                    transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                }

                &[aria-expanded="true"]::after {
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    margin-top: -1px;
                }
            }
        }

    }

    .dropdown-menu {
        background-color: #e5f1f8;
        border: none;
        border-top: 1px;
        padding: 0;
        top: 32px;
        left: 50%;
        transform: translate(-50%, 54px);
        min-width: 30rem;
        text-align: center;

        /* &::before {
            content: "";
            display: block;
            width: 40%;
            border-top: 5px #0071be solid;
            margin: -10px auto 0;
        } */

        @include tabletL {
            background-color: transparent;
            min-width: 100%;
            left: 0;
            transform: translate(0, 15px);

            .dropdown-item {
                background-color: #e5f1f8;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 5px;
                text-align: left;
            }
        }
    }
    @media screen and (min-width: 991px) {
        .dropdown:hover>.dropdown-menu {
            display: block;
        }
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-item  {
        color: #00349d;
        font-size: 16px;
        padding: 10px;

        &:first-child,
        &:last-child {
            border-radius: 0;
        }
    }

    .mail-contact {
        background-color: #00349d;
        color: #fff;
        position: absolute;
        text-align: center;
        top: 0;
        right: 0;
        padding: 20px 30px;
        z-index: 8000;
        text-decoration: none;
        @media screen and ( max-width:80em) {
            padding: 20px;
        }

        @include tabletL {
            right: 45px;
            padding: 5px;
            width: 45px;
            height: 45px;
        }

        &:hover {
            color: #ffda06 !important;
            opacity: 1;
        }

        img {
            width: 28px;
            max-width: 100%;
            @include tabletL {
                width: 18px;
            }
        }
        span {
            font-size: 12px;
        }
    }

    @include tabletL {
        .contact-btn {
            background-color: #ffda06;
            border-radius: 25px;
            color: #343434;
            font-weight: 600;
            display: block;
            margin: 0 auto;
            text-align: center;
            padding: 8px;
            margin: 20px auto 100px;
            width: 600px;
            max-width: 100%;
            position: relative;

            &::after {
                content: "";
                border-top: 2px solid #343434;
                border-right: 2px solid #343434;
                display: inline-block;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 50%;
                right: 25px;
                transform: translate(0,-50%) rotate(45deg);
            }
        }

        .sns-fb {
            a {
                width: 100%;
                text-align: center;
            }
        }
    }
  }

/* ~1024px */
@media screen and (max-width: 1440px) {
    #header {
        .dropdown-toggle {
            font-size: 14px;
        }
    }
}

@media screen and (max-width:991px) {
    #header {
        .navbar-expand-lg .navbar-nav {
            margin-right: 0;
        }
    }
}

/* ==[ 下層ページ ]==================================== */
#page {
    .page-header {
        margin-top: 80px;

        @include tabletL {
            margin-top: 45px;
        }
    }
    .jumbotron {
        padding: 0;

        .page-header-bg {
            margin: 0 auto;
            width: 100%;
            max-width: 100%;
        }

        .object-fit-img {
            @include tabletP {
                height: 160px;
            }
        }

        .container-fluid {
            padding: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .page-title {
            color: #fff;
            font-family: 'tbudrgothic-std', sans-serif;
            font-size: 36px;
            font-weight: 700;
            text-align: left;
            @include sphoneP {
                font-size: 28px;
            }
        }
    }
}

@charset "utf-8";

footer {
	background-color: #e5f1f8;
	padding: 80px 0;

	@include tabletP {
		padding: 50px 0 20px;
	}


	.footer-logo {
		margin-bottom: 10px;

		@include sphoneP {
			width: 220px;
		}
	}

	a {
		&:hover {
			color: #ffda06;
		}
		&::after {
			&:hover {
				color: #ffda06;
			}
		}
	}

	address {
		color: #00349d;
		font-size: 18px;
		font-weight: 500;
		ul {
			li {
				 font-size: 18px;

				 @include sphoneP {
					font-size: 14px;
				}

				&:first-child {
					margin-right: 20px;
				}
				.tel-link {
					color: inherit;

					&:hover {
						color: #ffda06;
					}
				}
			}
		}
	}

	.footer-nav {
		.nav-link {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 10px;
			color: #00349d;

			@include sphoneP {
				font-size: 14px;
			}

			&:hover::before {
				border-top-color: #ffda06;
				border-right-color: #ffda06;
			}

			&::before {
				content: "";
				border-top: 2px solid rgba(0, 52, 157, .5);
				border-right: 2px solid rgba(0, 52, 157, .5);
				display: inline-block;
				margin-bottom: 3px;
				margin-right: 10px;
				width: 10px;
				height: 10px;
				transform: rotate(45deg);
			}
		}
		.subNav {
			margin-top: 15px;
			margin-left: 15px;

			.suvNav-link {
				font-size: 16px;
				font-weight: normal;
				color: #00349d;
			
				&::before {
					content: "-";
					display: inline-block;
					margin-right: 5px;
					margin-bottom: 5px;
				}
			}
		}
	}
	.order-md-1 {
		margin-bottom: 30px;
		position: relative;

		&.copyright-wrap {
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
	.order-md-3 {
		margin-bottom: 30px;
	}

	.icon-wrap {
		a {
			color: #00349d;
		}
	}
}


/* ==[ .copyright ]==================================== */
.copyright-wrap {
	.copyright {
	color: #859ed0;
	font-size: 18px;

		@include sphoneP {
			font-size: 14px;
		}
	}
}


/* ==[ #page-top ]==================================== */
#page-top {
	background-color: #0071be;
	position: fixed;
	right: 0;
	width: 50px;
	height: 50px;
	z-index: 1000;
	transition: 0.3s;
  
	a {
	  display: block;
	  width: 50px;
	  height: 50px;
	  text-decoration: none;
	  text-align: center;
	  position: relative;
  
	  &::before {
		content: "";
		height: 10px;
		width: 10px;
		display: block;
		border: 3px solid #fff;
		border-left-width: 0;
		border-bottom-width: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -30%) rotate(-45deg);
	  }
	}
  }


/* ==[ #scroll-item ]==================================== */
#scroll-item {
	position: fixed;
	top: 50%;
	right: 20px;
	width: 15px;
	height: auto;
	z-index: 1000;

	@include pc {
		top: 25%;
	}

	@include sphoneP {
		top: 35%;
	}
	
	img {
		width: 15px;
		height: auto;
	}
}
@charset "utf-8";
@import '../setting';
@import '../mixin';

/*
page
*/
$id: page;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';

/*
#overview
modContents
*/

/*
#styleguide
xx

```
```
*/
.modContentsContainer {
	padding-bottom: 50px;
}

.modContents {
	.alignleft {
		display: inline;
		float: left;
		margin-right: 1.5em;
		margin-bottom: 1.5em;
	}

	.alignright {
		display: inline;
		float: right;
		margin-left: 1.5em;
		margin-bottom: 1.5em;
	}

	.aligncenter {
		margin-bottom: 20px;
		clear: both;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	h1:before,
	h2:before,
	h3:before,
	h4:before,
	h5:before,
	h6:before,
	h1:after,
	h2:after,
	h3:after,
	h4:after,
	h5:after,
	h6:after {
		content: "";
		display: table;
		table-layout: fixed;
		clear: both;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
		border: none;
	}

	ul {
		padding-left: 10px;
		margin-bottom: 30px;
		list-style: none;

		li {
			padding-left: 20px;
			position: relative;
			word-wrap: break-word;
			margin-bottom: 10px;
		}

		li:before {
			content: "";
			width: 10px;
			height: 10px;
			position: absolute;
			top: 10px;
			left: 2px;
			background: #00349D;
		}
	}

	ol {
		list-style: none;
		counter-reset: ol_li;
		/* padding-left: 10px; */
		padding-left: 0;
		margin-bottom: 30px;

		li {
			/* padding-left: 20px; */
			padding-left: 30px;
			position: relative;
			word-wrap: break-word;
			margin-bottom: 10px;
		}

		li:before {
			position: absolute;
			top: 0;
			left: 0;
			counter-increment: ol_li;
			content: "0" counter(ol_li);
			font-weight: bold;
			color: #00349D;
		}
	}

	dl.news-list {
		border: 3px #E4F1F9 solid;
		display: flex;
		align-items: center;
		padding: 30px 60px;
		width: 100%;
		max-width: 100%;
		margin: 0 auto 20px;
		@include tabletP {
			padding: 10px;
		}

		@include tabletL {
			flex-wrap: wrap;
		}

		dt {
			width: 37%;
			@include tabletL {
				margin-bottom: 10px;
				width: 100%;
				max-width: 100%;
			}
			.cate-item {
				border: 1px #0071be solid;
				color: #0071be;
				display: inline-block;
				font-size: 14px;
				padding: 3px 10px;
				text-align: center;
				margin-right: 10px;
				width: 140px;
				@include sphoneP {
					font-size: 12px;
					width: 100px;
				}
			}
			.post-date {
				font-style: italic;
				display: inline-block;
			}
		}
		dd {
			width: 100%;
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			.post-cont {
				color: #343434;

				&:hover {
					color: #0071be;
				}
			}
		}
		
	}

	.wp-caption,
	img {
		max-width: 100%;
		height: auto;
	}

	.wp-caption {
		p {
			margin: 0;
		}
	}

	h2 {
		position: relative;
		font-size: 30px;
		color: #0071BE;
		padding: 10px;
		margin-bottom: 30px;
		text-align: center;
		font-family: 'Noto Serif JP',
		serif;
		&::after {
			border-bottom: 3px #0071be solid;
			border-radius: 10px;
			content: "";
			display: block;
			width: 30px;
			margin: 10px auto 0;
		}
		@include sphoneP {
			font-size: 24px;
		}

	}

	h3 {
		background-color: #2075BC;
		color: #fff;
		font-size: 26px;
		padding: 10px;
		padding-left: 20px;
		font-weight: bold;
		text-align: left;
		margin-bottom: 30px;
	}

	h4 {
		font-size: 22px;
		font-weight: 600;
		color: #0071BE;
		padding: 0;
		margin-bottom: 30px;
		text-align: left;
	}

	h5 {
		color: #343434;
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 20px;
	}

	p {
		color: #202020;
		margin-bottom: 30px;
	}

	.pagination {
		padding-left: 0;
		justify-content: center;

		@include sphoneP {
			justify-content: space-around;
		}

		li {
			padding-left: 0;
			margin: 0 10px;

			@include sphoneP {
				margin: 0 5px;
			}

			&::before {
				display: none;
			}
		}

		.page-item {
			&.active {
				.page-link {
					background-color: #0071BE;
					color: #fff;
				}
			}

			&:first-child,
			&:last-child {
				.page-link {
					color: #0071BE;
					position: relative;
				}
			}

			&:first-child {
				.page-link {

					&::before {
						content: "";
						width: 6px;
						height: 6px;
						border: 0;
						border-bottom: 1px #0071BE solid;
						border-left: 1px #0071BE solid;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translate(0, -35%) rotate(45deg);
					}
				}
			}

			&:last-child {
				.page-link {

					&::after {
						content: "";
						width: 6px;
						height: 6px;
						border: 0;
						border-top: 1px #2075BC solid;
						border-right: 1px #2075BC solid;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translate(0, -35%) rotate(45deg);
					}
				}

			}

			.page-link {
				border: none;
				color: #0071BE;
				font-style: italic;
				padding: 5px 10px;
				font-weight: 600;

				@include sphoneP {
					padding: 5px 7px;
				}
			}
		}
	}

	input[type="text"],
	input[type="email"],
	input[type="url"],
	input[type="password"],
	input[type="search"],
	input[type="number"],
	input[type="tel"],
	input[type="range"],
	input[type="date"],
	input[type="month"],
	input[type="week"],
	input[type="time"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="color"],
	textarea {}

	input[type="text"]:focus,
	input[type="email"]:focus,
	input[type="url"]:focus,
	input[type="password"]:focus,
	input[type="search"]:focus,
	input[type="number"]:focus,
	input[type="tel"]:focus,
	input[type="range"]:focus,
	input[type="date"]:focus,
	input[type="month"]:focus,
	input[type="week"]:focus,
	input[type="time"]:focus,
	input[type="datetime"]:focus,
	input[type="datetime-local"]:focus,
	input[type="color"]:focus,
	textarea:focus {}

	iframe {
		width: 100%;
	}

	a {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.btn_pdf {
		display: inline;
		padding-right: 60px;
		background: url('#{$imgPath}bg_pdf.png') no-repeat right 10px center;
	}

	.btn_doc {
		display: inline;
		padding-right: 60px;
		background: url('#{$imgPath}bg_doc.png') no-repeat right 10px center;
	}

	.btn_xls {
		display: inline;
		padding-right: 60px;
		background: url('#{$imgPath}bg_xls.png') no-repeat right 10px center;
	}

	.btn_ppt {
		display: inline;
		padding-right: 60px;
		background: url('#{$imgPath}bg_ppt.png') no-repeat right 10px center;
	}

	.btn_link {
		display: inline;
		padding: 5px 40px 5px 0;
		background: url('#{$imgPath}bg_blank.png') no-repeat right 10px center;
	}

	.btn01 {
		font-size: 16px;
		display: inline-block;
		background-color: #fff;
		border: 1px solid #2075BC;
		color: #2075BC;
		padding: 20px;

		margin-right: 20px;
		margin-bottom: 10px;
		text-align: center;
		width: 420px;
		max-width: 100%;

		&:hover {
			background-color: #2075BC;			
			color: #fff;
			text-decoration: none;
		}
	}
	.CntactArea {
		tbody {
			tr {
				th { 
					background-color: #e6e6e6; 
					border: 1px #b3b3b3 solid; 
					color: #555; 
					width: 30%;
				}
				td {
					background: #fff;
					border: 1px #b3b3b3 solid;
					color: #555;
					width: 70%;
				}
			}
		}
		.c-red { color: #ff0000; }
	}
	table {
		max-width: 100%;
		width: 100%;
		margin-bottom: 30px;
		border-collapse: collapse;
		border-spacing: 5px;

		/* table-layout: fixed; */
		th,
		td {
			padding: 20px;

			@include sphoneP {
				padding: 10px;
			}
		}

		thead {

			th,
			td {
				text-align: left;
				font-weight: bold;
				background: #2075BC;
				border: 1px #0071BE solid;
				color: #fff;
			}
		}

		tbody {
			th {
				background: #fff;
				border: 1px #0071BE solid;
				font-size: 20px;
				color: #555;
				width: 20%;

				@include sphoneP {
					width: 30%;
				}
			}

			td {
				background: #fff;
				border: 1px #0071BE solid;
				color: #555;
				width: 80%;

				@include sphoneP {
					width: 70%;
				}
			}
		}

		.wp-caption {
			p {
				margin: 0;
			}

			width: auto !important;

			img {
				max-width: 100%;
				padding: 0;
				width: auto;
				height: auto;
			}
		}

		&.tbl_none {
			margin: 0;
			padding: 0;
			display: table;
			table-layout: fixed;

			th,
			td {
				background: none;
				border: none;
				vertical-align: top;
				text-align: left;
				margin: 0;
				padding: 10px;
			}
		}

		&.tbl_fix {
			table-layout: fixed;
		}

		&.tbl_responsive {
			table-layout: fixed;
		}
	}

}

@media screen and (max-width: 767px) {
	.modContents {

		.alignleft,
		.alignright {
			display: block;
			float: none;
			margin: 0 0 20px 0;
		}

		.scroll_wrap {
			margin-bottom: 30px;
			width: 100%;
			overflow: auto;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			-webkit-overflow-scrolling: touch;

			&:before {
				content: "→スクロールできます。"
			}
		}

		.tbl_scroll {
			margin-bottom: 0;
			max-width: 767px !important;
			width: 767px !important;
		}

		.tbl_responsive,
		.tbl_responsive thead,
		.tbl_responsive tfoot,
		.tbl_responsive tbody,
		.tbl_responsive tr,
		.tbl_responsive th,
		.tbl_responsive td {
			display: inline-block;
			width: 100% !important;
			width: calcl(100%/2);
		}

		.tbl_responsive_02 th,
		.tbl_responsive_02 td {
			display: inline-block;
			width: 50% !important;
		}

		.list_1 li {
			width: 100%;
		}

		.list_1 li:nth-child(even) {
			margin-left: 0;
		}
	}
}

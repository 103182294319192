@charset "utf-8";
@import "~bootstrap/scss/bootstrap.scss";
@import "~slick-carousel/slick/slick.scss";
@import "_setting";
@import "_mixin";
@import "_reset";
@import "_base";
@import "_layout";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,700&display=swap');

@import "module/*";

@charset "utf-8";

/*
#overview
pankuzu
wp-pagenavi
*/

/*
#styleguide
xx

```
```
*/
.breadcrumb-wrap {
	display: block;
	/* padding-left: 15px; */
	/* margin-bottom: 50px; */
	padding: 0 15px;
}

.breadcrumb.pankuzu {
	background-color: #fff;
	padding: 15px 0;
	/* margin-bottom: 80px; */
}

.breadcrumb-item {
	a {
		color: #3C379E;
		text-decoration: none;
		margin-bottom: 3px;
		@include sphoneP {
			font-size: 14px;
		}
	}

	+.breadcrumb-item::before {
		content: "";
		display: inline-block;
		width: 8px;
		height: 8px;
		margin-right: 10px;
		border-top: 1px solid #333;
		border-right: 1px solid #333;
		transform: rotate(45deg);
		margin-bottom: 3px;
	}

	&.active {
		color: #333333;
	}
}

.wp-pagenavi {
	display: table;
	margin: 0 auto 20px;
	border-collapse: separate;
	border-spacing: 15px 0;
	a,
	span {
		font-weight: bold;
		height: 40px;
		width: 40px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		padding: 0 !important;
	}
	a {
		border: 2px solid #DFDFDB !important;
		&:hover {
			border: 2px solid #0060AE !important;
			color: #fff;
			background: #0060AE;
			text-decoration: none;
		}
		&.previouspostslink,
		&.nextpostslink{
			color: #fff;
			border: 2px solid #9B9EA4 !important;
			background: #9B9EA4;
		}
	}
	span {
		border: 2px solid #0060AE !important;
		color: #fff;
		background: #0060AE;
	}
}





@charset "utf-8";
/*
#overview
modBtn
*/

/*
#styleguide
xxx

```
```
*/

/*
page
*/
$id: page;
$cmnPath:'../img/common/';
$imgPath:'../img/#{$id}/';

input[type="submit"]{
	font-size: 21px;
	display: inline-block;
	border: 1px solid #1870b9;
    background: #2075bc;
	padding: 25px 60px;
	color: #fff;
	margin-right: 20px;
	margin-bottom: 10px;
	max-width: 420px;
	width: 100%;
}
input[type="button"] {
	font-size: 21px;
	display: inline-block;
	border: 1px solid #8a8a8a;
	background: #a0a0a0;
	padding: 25px 60px;
	color: #fff;
	margin-right: 20px;
	margin-bottom: 10px;
	max-width: 420px;
	width: 100%;
}
